import * as React from "react";
import Layout from "../../components/Layout";
import PortfolioItem from "../../components/PortfolioItem";

import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
  {
    allContentfulPortfolioItem(sort: { fields: updatedAt, order: DESC }) {
      nodes {
        title
        key: id
        subtitle
        slug
        featuredImage {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`;

const PortfolioPage = () => {
  const {
    allContentfulPortfolioItem: { nodes: portfolioItems },
  } = useStaticQuery(query);

  return (
    <Layout pageTitle="Portfolio">
      <div data-section="portfolio" className="portfolio main-section">
        <div className="main-section-inner text-container">
          <div className="portfolio-intro">
            <h2>Portfolio</h2>

            <p>
              Here's a selection of some of the projects I've been involved in
              launching. If you want more, take a look at my{" "}
              <a href="http://playground.alextebbs.com">playground</a> where I
              host some of my side projects.
            </p>
          </div>

          <div className="portfolio-items">
            {portfolioItems.map((portfolioItem) => (
              <PortfolioItem {...portfolioItem} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PortfolioPage;
