import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const PortfolioItem = ({ title, subtitle, featuredImage, slug }) => {
  const image = getImage(featuredImage);

  return (
    <div className="portfolio-item">
      <Link to={`/portfolio/${slug}`}>
        <div className="portfolio-image-wrap">
          <div className="image-wrapper">
            <GatsbyImage alt={title} image={image} />
          </div>
        </div>
        <div className="portfolio-item-meta">
          <div className="meta-main">
            <h3>{title}</h3>
            <p className="subtitle">{subtitle}</p>
          </div>
          <div className="see-more">See More</div>
        </div>
      </Link>
    </div>
  );
};

export default PortfolioItem;
